@font-face{
  font-family:"Circular";
  src:url(/fonts/LL-Circular-Family-Web/lineto-circular-book.eot), url(/fonts/LL-Circular-Family-Web/lineto-circular-book.eot?#iefix) format("embedded-opentype"), url(/fonts/LL-Circular-Family-Web/lineto-circular-book.woff2) format("woff2"), url(/fonts/LL-Circular-Family-Web/lineto-circular-book.woff) format("woff");
}
html, body {
  overflow-x: hidden;
}
body {
  font-family: "Circular", Arial, Helvetica, sans-serif;
  margin:0;
  position: relative;
}
h1 {
  font-weight: 900;
  font-size: 40px;
  margin:0;
  margin-top: 25px;
  margin-bottom: 20px;
}
#top{
  clear:both;
  padding-top: 30px;
  padding-left:12%;
  padding-right:12%;
}
#top img{
  width:185px;
}
.video-wrapper {
  position: relative;
	padding-bottom: 32%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  width: 60%;
  margin:auto;
  margin-top:15px;
  margin-bottom:45px;
}
.video-wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.topText{
  background: radial-gradient(circle at top left,#ECFFFB 0%,#00D4F7 50%,#5D93FD 100%);
  width:100%;
  padding-left:20%;
  padding-right:16%;
  margin-left:-20%;
  padding-top:0;
  padding-bottom:15px;
}
.topText h1{
  padding-top:15px;
}
.topText a{
  transition: all .3s ease-in-out;
}
a:hover{
  text-decoration: none;
}
.lang{
  float:right;
}
#bottom{
  clear:both;
  background-color: #000;
  padding: 30px;
  padding-left:12%;
  padding-right:12%;
  color: #fff;
  font-size: 12px;
  margin-top:20px;
}
#bottom hr{
  margin-bottom:15px;
}
.bottomlogo{
  float: left;
}
.privacy{
  float: right;
  margin:0;
  padding:0;
}
.privacy a{
  color: #fff;
}
.contact{
  float:right;
}
.contact a{
  color: #fff;
}
.socialmedialogos img{
  width:20px;
  margin-left:5px;
}
.socialmedialogos img:hover{
  opacity: 0.75;
}
#random{
  clear:both;
  padding-left:12%;
  padding-right:12%;
}
#articles{
  clear:both;
  width: 100%;
  padding: 0 0 0 5%;
}
.article{
  width:29.1%;
  float:left;
  display: block;
  top:-1px;
  padding-bottom: 10px;
  border: 1px solid #ccc;
  margin: 0 0 10px 0;
  min-height: 450px;
  max-height: 450px;
  position:relative;
  transition: all .4s ease;
}
.article:nth-child(2){
  margin: 0 20px 10px 20px;
}
.article:nth-child(5){
  margin: 0 20px 10px 20px;
}
.article a:hover {
  background: #eee;
}
.article:hover .articleTitle{
  color: #2878c9 !important;
}
.articleImage{
  height:200px;
  width: 100%;
  background-size: cover;
  transition: all .4s ease;
  background-repeat: no-repeat;
}
.articleImageVertical{
  height:200px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 55%;
  background-position-x: center;
  background-position-y: -30px;
}
.card-angle-edge::before {
  content: '';
  background: radial-gradient(circle at top left,#ECFFFB 0%,#00D4F7 50%,#0079FF 100%);
  width: 100%;
  left: 0;
  height: 8px;
  position: absolute;
  transform: skewY(-5deg);
  -ms-transform: skewY(-5deg);
  -webkit-transform: skewY(-5deg);
  bottom: 20px;
  z-index: 40;
}
.card-angle-edge::after {
  content: '';
  background-color: 
  #fff;
  width: 100%;
  left: 0;
  height: 55px;
  position: absolute;
  transform: skewY(-5deg);
  -ms-transform: skewY(-5deg);
  -webkit-transform: skewY(-5deg);
  bottom: -34px;
  z-index: 50;
}
.articleTitle{
  margin-top:57px;
  font-size: 19px;
  font-weight:bold;
  margin-bottom:10px;
}
.card-angle-edge{
  width: 100%;
  height: 100%;
  position: relative;
}
.article p{
  margin-left:15px;
  margin-right:15px;
}
.articleDesc{
  font-size:15px;
  margin-top:-5px;
  margin-bottom:-5px;
}
.articleDate{
  font-size:15px;
  margin-top:-15px;
}
.clear{
  clear: both;
}
.seeMore{
  clear: both;
  float:right;
  padding-right:4%;
}
.article a{
  text-decoration: none;
  color: black;
}
.article:hover{
  -webkit-box-shadow: 0px 0px 26px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px 0px 26px 0px rgba(0,0,0,0.15);
  box-shadow: 0px 0px 26px 0px rgba(0,0,0,0.15);
}
.article:hover .articleImage{
  opacity: 0.5;
  background-color: #0079FF;
}
.article:hover::after {
  width: calc(100% + 2px);
}
.article::after {
  content: '';
  transition: all .4s ease;
  height: 5px;
  width: 0;
  position: absolute;
  bottom: -3px;
  left: -1px;
  z-index: 50;
  background: radial-gradient(circle at top left,#ECFFFB 0%,#00D4F7 50%,#0079FF 100%);
}
a{
  color: #000;
}
.gone{
  display:none;
}
iframe{
  margin-top:15px;
}

@media (max-width: 840px){
  .articleTitle{
    margin-top:12%;
  }
  .article:nth-child(2){
    margin:0;
    margin-bottom:20px;
  }
  .video-wrapper {
    width: 124%;
    padding-bottom: 63%; /* 16:9 */
    margin-left:-12%;
  }
  .article{
    width:80%;
    margin:0;
    margin-bottom:20px;
  }
  #articles{
    width:100%;
    margin:0;
    padding:0;
  }
  #random{
    width:100%;
    padding:0;
    padding-left:10%;
  }
  .article:nth-child(n+4){
    display:none;
  }
  .card-angle-edge::before {
    height: 8px;
    transform: skewY(-5deg);
    -ms-transform: skewY(-5deg);
    -webkit-transform: skewY(-5deg);
    bottom: 25px;
  }
  .card-angle-edge::after {
    height: 55px;
    transform: skewY(-5deg);
    -ms-transform: skewY(-5deg);
    -webkit-transform: skewY(-5deg);
    bottom: -30px;
  }
  .seeMore{
    clear: both;
    float:right;
    padding-right:20%;
  }
  #bottom{
    clear:both;
    background-color: #000;
    padding: 30px;
    padding-left:12%;
    padding-right:12%;
    color: #fff;
    font-size: 12px;
    margin-top:20px;
  }
  #bottom hr{
    margin-bottom:15px;
  }
  .bottomlogo{
    float: none;
    text-align:center;
  }
  .privacy{
    float: right;
    margin:0;
    padding:0;
  }
  .privacy a{
    color: #fff;
  }
  .contact{
    float:none;
    text-align:center;
  }
  .contact a{
    color: #fff;
  }
  .gone{
    display:block;
  }  
  .socialmedialogos img{
    width:20px;
    margin-left:5px;
  }
  .socialmedialogos img:hover{
    opacity: 0.75;
  }
}

@media (min-width: 840px) and (max-width: 1050px){
  .article{
    min-height:500px;
    max-height:500px;
  }
}

@media (min-width: 768px) and (max-width: 768px){
  .card-angle-edge{
    display: none;
  }
}